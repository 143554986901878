import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src281035820/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "apis"
    }}>{`APIs`}</h1>
    <h2 {...{
      "id": "rest-api-documentation"
    }}>{`REST API Documentation`}</h2>
    <p>{`A complete documentation of our REST API capabilities is available on
our `}<a parentName="p" {...{
        "href": "http://api.kineticdata.com/"
      }}>{`Kinetic API`}</a>{` site. To understand what
options and includes are available that site should be consulted.`}</p>
    <h2 {...{
      "id": "common-parameters"
    }}>{`Common Parameters`}</h2>
    <p>{`Nearly all of our REST API JavaScript functions will accept the following
option parameters:`}</p>
    <pre><code parentName="pre" {...{}}>{`* include -> string = a string of includes, e.g. "details,attributes"
* limit -> number = the number of results the request is limited to
* pageToken -> string = the unique ID used to fetch the next page
* q -> string = an optional query string for searching
* direction -> string = 'ASC' or 'DESC' for ascending or descending.
* orderBy -> string = the column by which to order the results.
* manage -> boolean = limit results to entries which can be managed by the current user
* export -> boolean = ignore the includes and normalize on an export response
* count -> boolean = request counts for supported APIs
* public -> boolean = excludes the X-Kinetic-AuthAssumed header
`}</code></pre>
    <h2 {...{
      "id": "apis-to-document"
    }}>{`APIs to document`}</h2>
    <p>{`SubmissionSearch
searchSubmissions
√ fetchSubmission
√ createSubmission
√ deleteSubmission
√ updateSubmission
√ fetchForm
√ updateForm
√ deleteForm
√ createForm
√ fetchForms
√ fetchCategories
√ updateKapp`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      